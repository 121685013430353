import React, { FC } from "react"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"

const rossi: FC = () => {
  const titleImage = "_DSC2142.jpg"
  const image1 = "_DSC2139.jpg"
  const image2 = "_DSC2141.jpg"
  const image3 = "_DSC2143.jpg"

  return (
    <Layout>
      <div className="single-project">
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Rossi/" + titleImage}
            alt="Ghidoni"
          />
        </div>
        <h3 className="single-project-picture-landscape">
          Rossi 2020
          <h5 className="single-project-text">
            A Mergoscia non ci sono molti parcheggi e spesso vengono utilizzati
            dai proprietari di rustici sui monti sopra Mergoscia. Ne deriva che
            spesso quelli pubblici in cima al paese sono tutti occupati. Il
            Signor Bruno Rossi ha quindi acquistato un terreno edificabile e ci
            ha incaricato di fare un progetto. Presto abbiamo scoperto che il
            modo più razionale per creare dei posteggi era quello di inserirli
            parallelamente alla via Cantonitt e alla Via Redonda in modo da
            ridurre il volume di scavo e la superficie dei muri. Si sono
            costriti muri in pietra naturale, inserito un sistema di drenaggio,
            messo un parapetto riprendendo lo stile delle ringhiere comunali e
            fatta una pavimentazione conforme all’utilizzo. In previsione futura
            sono state eseguite le necessarie predisposizioni per delle colonne
            di ricarica di veicoli elettrici.
          </h5>
        </h3>
        <div className="single-project-picture-landscape">
          <StaticImage
            src={"../../assets/images/Rossi/" + image3}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Rossi/" + image1}
            alt="Alambicco"
          />
        </div>
        <div>
          <StaticImage
            src={"../../assets/images/Rossi/" + image2}
            alt="Alambicco"
          />
        </div>
      </div>
    </Layout>
  )
}

export default rossi
